import * as React from 'react'

const IndexPage = () => {

  return (
    <main>
      home
    </main>
  )
}

export default IndexPage
